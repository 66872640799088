import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export type Cart = {
  items: {
    priceId: string;
    name: string;
    machineSlug: string;
  }[];
};

export type CartAction =
  | {
      type: 'add';
      item: {
        priceId: string;
        name: string;
        machineSlug: string;
      };
    }
  | {
      type: 'remove';
      item: {
        machineSlug: string;
      };
    }
  | {
      type: 'clear';
    };

const cartStorageAtom = atomWithStorage<Cart>('naamdeo-courses-shopping-cart', { items: [] });

export const cartReducer = (prev: Cart, action: CartAction): Cart => {
  if (action.type === 'add') {
    return {
      ...prev,
      items: [...prev.items, action.item],
    };
  } else if (action.type === 'remove') {
    return {
      ...prev,
      items: prev.items.filter((i) => i.machineSlug != action.item.machineSlug),
    };
  } else if (action.type === 'clear') {
    return {
      ...prev,
      items: [],
    };
  } else {
    throw new Error('Invalid action type for cartReducer');
  }
};

export const cartAtom = atom<Cart, CartAction[], void>(
  (get) => get(cartStorageAtom),
  (get, set, action) => {
    set(cartStorageAtom, cartReducer(get(cartStorageAtom), action));
  }
);
